import React, { useState, useEffect, useRef } from 'react';
import './Comments.css';
import ImageGallery from '../../Atom/Image/Image';
import UserProfile from "../../../assets/images/User_profile.png";
import Up_down_arrow from "../../../assets/images/svg/Up_down_arrow.svg";
import TextArea from '../../Atom/TextArea/TextArea';
import CommentView from '../CommentView/CommentView';
import { getComments, addComment, deleteComment } from './helper';
import Button from '../../Atom/Button/Button';
import Bell from "../../../assets/images/svg/bell.svg";
import useCustomComposable from "../../utils/composables";

const Comments = ({ taskId }) => {
    const { makeUniqueId } = useCustomComposable();
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [editingIndex, setEditingIndex] = useState(null);
    const [isRecentFirst, setIsRecentFirst] = useState(true);
    const [showAllComments, setShowAllComments] = useState(false); 
    const textAreaRef = useRef(null);
    const commentsContainerRef = useRef(null);
    const [pinnedComments, setPinnedComments] = useState(null);

    useEffect(() => {
        const storedComments = getComments(taskId);
        const storedPinned = JSON.parse(localStorage.getItem(`pinned_${taskId}`)) || [];
        setComments(storedComments ? Object.values(storedComments) : []);
        setPinnedComments(storedPinned);
    }, [taskId]);

    const formatTime = (timeString) => {
        const date = new Date(timeString);
        const now = new Date();
        const diffInSeconds = Math.floor((now - date) / 1000);
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);

        if (diffInSeconds < 60) return "Just now";
        if (diffInMinutes < 60) return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
        if (diffInHours < 24) return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
        return diffInDays === 1 ? "Yesterday" : `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
    };

    const handleCommentChange = (e) => {
        setNewComment(e.target.value);
    };

    const handleKeyDown = (e) => {
        // Check for Ctrl+Enter or Cmd+Enter (for Mac)
        if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
            e.preventDefault(); // Prevent default behavior
            handleCommentSubmit();
        }
    };

    const handleCommentSubmit = () => {
        if (newComment.trim()) {
            if (editingIndex !== null) {
                const updatedComments = [...comments];
                updatedComments[editingIndex] = {
                    ...updatedComments[editingIndex],
                    message: newComment,
                    updatedAt: new Date().toISOString(),
                };
                setComments(updatedComments);
                setEditingIndex(null);
            } else {
                const commentData = {
                    id: makeUniqueId(),
                    message: newComment,
                    createdAt: new Date().toISOString(),
                    image: UserProfile,
                    name: "User Name",
                    updatedAt: new Date().toISOString(),
                    taskId,
                };
                addComment(taskId, commentData);
                setComments((prevComments) => [...prevComments, commentData]);
            }
            setNewComment('');
        }
    };

    const handleEditComment = (commentId) => {
        const originalIndex = comments.findIndex(comment => comment.id === commentId);
        if (originalIndex !== -1) {
            setEditingIndex(originalIndex);
            setNewComment(comments[originalIndex].message);
            if (textAreaRef.current) textAreaRef.current.focus();
        }
    };

    const handleDeleteComment = (commentId) => {
        const originalIndex = comments.findIndex(comment => comment.id === commentId);
        if (originalIndex !== -1) {
            deleteComment(taskId, originalIndex);
            const updatedComments = getComments(taskId);
            setComments(updatedComments ? Object.values(updatedComments) : []);
        }
    };

    const toggleCommentOrder = () => {
        setIsRecentFirst(!isRecentFirst);
    };

    const togglePinComment = (commentId) => {
        setPinnedComments((prevPinned) => {
            const isPinned = prevPinned.includes(commentId);
            const updatedPinned = isPinned
                ? prevPinned.filter((id) => id !== commentId)
                : [...prevPinned, commentId];

            localStorage.setItem(`pinned_${taskId}`, JSON.stringify(updatedPinned));
            return updatedPinned;
        });
    };

    // Sorting logic
    const sortedComments = [...comments]
        .filter(comment => !pinnedComments.includes(comment.id))
        .sort((a, b) => isRecentFirst
            ? new Date(b.createdAt) - new Date(a.createdAt)
            : new Date(a.createdAt) - new Date(b.createdAt));

    // Combine pinned and sorted comments
    const finalComments = [
        ...comments.filter(comment => pinnedComments.includes(comment.id)),
        ...sortedComments
    ];

    const getVisibleComments = () => {
        if (finalComments.length <= 3 || showAllComments) {
            return finalComments; // Show all comments when expanded or if less than 3 comments exist
        }
        return [
            finalComments[0], // First comment
            finalComments[1], // Second comment
            { id: "more", isPlaceholder: true, count: finalComments.length - 3 }, // Placeholder for more comments
            finalComments[finalComments.length - 1] // Last comment
        ];
    };

    // Auto-scrolling effect
    useEffect(() => {
        if (commentsContainerRef.current) {
            const container = commentsContainerRef.current;
            if (isRecentFirst) {
                container.scrollTop = 0; // Scroll to top for newest first
            } else {
                container.scrollTop = container.scrollHeight; // Scroll to bottom for oldest first
            }
        }
    }, [comments, isRecentFirst]); // Trigger on comments change or order change

    return (
        <div className="comments-section">
            <div className="comments-header">
                <div>Comments</div>
                <div className="toggle-order">
                    <Button
                        onClick={toggleCommentOrder}
                        type="default"
                        text={isRecentFirst ? 'Oldest First' : 'Newest First'}
                    />
                    <img
                        style={{ transform: isRecentFirst ? 'rotate(0deg)' : 'rotate(180deg)' }}
                        src={Up_down_arrow}
                        alt="Sort Order"
                    />
                </div>
            </div>
            {/* Show the "Hide earlier comments" button at the top if comments are expanded */}
            {showAllComments && (
                <button
                    className="more-comments-btn"
                    onClick={() => setShowAllComments(false)} // Hide earlier comments
                >
                    Hide earlier comments
                </button>
            )}
            <div className="comments-container" ref={commentsContainerRef}>
                <div className="comment-view-container style-scroll">
                    {getVisibleComments().map((comment, index) => (
                        comment.isPlaceholder ? (
                            // Show the "Show more comments" button in between
                            <button
                                key={comment.id}
                                className="more-comments-btn"
                                onClick={() => setShowAllComments(prev => !prev)} // Clickable button for more comments
                            >
                                {showAllComments ? "Hide earlier comments" : `Show ${comment.count} more comments`}
                            </button>
                        ) : (
                            <CommentView
                                key={comment.id}
                                comment={comment}
                                index={index}
                                time={formatTime(comment.createdAt)}
                                onDelete={() => handleDeleteComment(comment.id)}
                                onEdit={() => handleEditComment(comment.id)}
                                onPin={() => togglePinComment(comment.id)}
                                isPinned={pinnedComments.includes(comment.id)}
                            />
                        )
                    ))}
                </div>
                <div className="comment" style={{ display: "flex", flexDirection: 'column' }}>
                    <div style={{ display: 'flex' }}>
                        <div className="profile-image">
                            <ImageGallery images={[UserProfile]} shape="circle" />
                        </div>
                        <div className='text-section'>
                            <TextArea
                                placeholder="Add a comment"
                                rows={5}
                                value={newComment}
                                onKeyDown={handleKeyDown} // Add key down handler
                                onChange={handleCommentChange}
                                ref={textAreaRef}
                            />
                        </div>
                    </div>
                    <div className="notification-icon">
                        <div>
                            <ImageGallery images={[Bell]} shape="circle" />
                        </div>
                        <div>
                            <Button
                                onClick={handleCommentSubmit}
                                type="primary"
                                text="Comment"
                                disabled={!newComment.trim()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Comments;
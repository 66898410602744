
export function useCustomComposable() {
    function makeUniqueId(length = 6) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }
    return {
        makeUniqueId
    };
}

export default useCustomComposable;

export const formatDisplayDate = (date) => {
    if (!date) return '';
    const dateObj = new Date(date);
    if (isNaN(dateObj.getTime())) return '';

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // Reset time parts for accurate comparison
    [today, tomorrow, yesterday, dateObj].forEach(d => {
        d.setHours(0, 0, 0, 0);
        d.setMinutes(0, 0, 0);
        d.setSeconds(0, 0);
    });

    if (dateObj.getTime() === today.getTime()) {
        return 'Today';
    } else if (dateObj.getTime() === tomorrow.getTime()) {
        return 'Tomorrow';
    } else if (dateObj.getTime() === yesterday.getTime()) {
        return 'Yesterday';
    }

    return `${dateObj.getDate().toString().padStart(2, '0')}/${(dateObj.getMonth() + 1).toString().padStart(2, '0')}`;
};
import React, { useEffect, useState } from 'react';
import './CommentCard.css';
import ImageGallery from '../Image/Image';

const CommentCard = () => {
    const [comments, setComments] = useState([]);

    useEffect(() => {
        const storedComments = JSON.parse(localStorage.getItem("comments")) || {};
        const storedTasks = JSON.parse(localStorage.getItem("Tasks")) || [];
        const allComments = [];

        Object.entries(storedComments).forEach(([taskId, taskComments]) => {
            Object.values(taskComments).forEach(comment => {
                const task = storedTasks.find(t => t.id === taskId);
                allComments.push({
                    ...comment,
                    taskName: task ? task.value : "Unknown Task",
                });
            });
        });

        allComments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        const recentComments = allComments.slice(0, 10).map(comment => ({
            ...comment,
            timeAgo: formatTimeAgo(new Date() - new Date(comment.createdAt))
        }));

        setComments(recentComments);
    }, []);

    const formatTimeAgo = (timeDiff) => {
        const minutes = Math.floor(timeDiff / (1000 * 60));
        const hours = Math.floor(timeDiff / (1000 * 60 * 60));

        if (minutes < 1) return "Just now";
        if (minutes < 60) return `${minutes} min${minutes > 1 ? "s" : ""} ago`;
        return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    };

    return (
        <div className="comment-card">
            <h3 className="comment-title">Recent Comments</h3>
            <div className="comment-list">
                {comments.length > 0 ? (
                    comments.map((comment) => (
                        <div key={comment.id} className="comment-item">
                            <div className="comment-header">
                                {/* <img src={comment.image} alt={comment.name} className="avatar" /> */}
                                <ImageGallery images={[comment.image]} shape="circle" />
                                <div>
                                    <span className="comment-username">{comment.name}</span>
                                    |
                                    <span className="comment-taskname">{comment.taskName}</span>
                                </div>
                            </div>
                            <div className='comment-body'>
                                {comment.isDeleted ? (
                                    <span className="deleted-message">This message is deleted</span>
                                ) : (
                                    <>
                                        <p className="comment-text">{comment.message}</p>
                                        <span className="comment-time">{comment.timeAgo}</span>
                                    </>
                                )}
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="no-comments">No recent comments</p>
                )}
            </div>
        </div>
    );
};

export default CommentCard;
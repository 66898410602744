import React, { useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import "./Style.css";
import DragIcon from "../../../assets/images/svg/Drag_icon.svg";
import InputBox from "../../Atom/Input/InputField";
import RangeBar from "../../Atom/RangBar/rangBar";
import Tags from "../../Atom/Tags/tagsV2";
import CircularProgressBar from "../../Atom/CircularProgressbar/circularProgressBar";
import MyDatePicker from "../../Atom/Date/Date";
import OrganisationIcon from "../../../assets/images/svg/Organisation.svg";
import Comments from "../../../assets/images/svg/Comments.svg";
import AssigneeIcon from "../../../assets/images/svg/Assignee.svg";
import Dropdown from "../../../assets/images/svg/DropDown_arrow.svg";
import TaskRow from "./TaskRow";
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from "react-redux";
import { addTagHelper, removeTagHelper } from "../../Atom/Tags/helper";
import { addTagToSection, updateSection, updateSectionTags } from "../../../store/Section/index";
import useCustomComposable from "../../utils/composables";
import AddRow from "./addRow";
import { useSearchParams } from "react-router-dom";
import data from '../../../data.json'; // Import the JSON file
import Button from "../../Atom/Button/Button";
const ItemType = "SECTION";

const DraggableSection = ({
    id,
    index,
    moveSection,
    item,
    inputValue,
    handleInputChange,
    taskValue,
    handleTaskChange,
    addRow,
    handleTaskRowChange,
    tags: sectionTags,
    onCreateTask,
    showMoreButton
}) => {
    const [tableHeaderData, setTableHeaderData] = useState([]);
    const [searchParams] = useSearchParams();
    // const [isSectionVisible, setIsSectionVisible] = useState(true);
    const [rows, setRows] = useState([]);
    // const [tasksToShow, setTasksToShow] = useState(10); 
    // const [showMore, setShowMore] = useState(false);
    // const dispatch = useDispatch();
    const tasks = useSelector((state) => state.tasks.tasks);

    // Fetch tableHeader from localStorage or use default data
    useEffect(() => {
        const savedData = getTableHeaderFromLocalStorage();
        if (savedData) {
            setTableHeaderData(savedData); // Set state with saved data
        } else {
            setTableHeaderData(data.tableHeader); // Default data
        }
    }, []); // Empty dependency array ensures this runs only once on mount

    // Fetch table header data from localStorage
    const getTableHeaderFromLocalStorage = () => {
        const storedData = localStorage.getItem("tableHeader");
        if (storedData) {
            return JSON.parse(storedData); // Parse and return the stored data
        } else {
            return null; // No stored data, return null
        }
    };

    // Apply saved widths to table on render
    useEffect(() => {
        if (tableHeaderData.length > 0) {
            tableHeaderData.forEach((header, index) => {
                const cells = document.querySelectorAll(`tr > *:nth-child(${index + 1})`);
                cells.forEach((cell) => {
                    cell.style.width = `${header.columnWidth}px`;
                    cell.style.minWidth = `${header.columnWidth}px`;
                });
            });
        }
    }, [tableHeaderData]);

    // Handle column resizing
    const handleResize = (e, index) => {
        e.preventDefault();
        e.stopPropagation();

        const th = e.target.closest("th");
        const table = th.closest("table");
        const resizeHandle = e.target;

        // Add visual feedback
        resizeHandle.classList.add("resizing");
        document.body.style.cursor = "col-resize";

        const startX = e.clientX;
        const startWidth = th.offsetWidth;
        const colIndex = Array.from(th.parentNode.children).indexOf(th);

        // Get all td cells in this column (excluding header)
        const tdCells = table.querySelectorAll(`tbody tr > td:nth-child(${colIndex + 1})`);

        // Calculate minimum width based only on td content
        let maxContentLength = 0;
        tdCells.forEach(cell => {
            const cellSpan = cell.querySelector('span');
            const cellText = cellSpan ? cellSpan.textContent : '';
            maxContentLength = Math.max(maxContentLength, cellText.length);
        });

        const minContentWidth = maxContentLength * 8;
        const minWidth = Math.max(colIndex === 0 ? 350 : minContentWidth, minContentWidth);

        const onMouseMove = (event) => {
            event.preventDefault();
            const deltaX = event.clientX - startX;

            // Calculate the new width
            const newWidth = Math.max(startWidth + deltaX, minWidth);

            // Update the header cell width and apply ellipsis styles
            th.style.width = `${newWidth}px`;
            th.style.minWidth = `${newWidth}px`;

            // Apply styles to the span inside th
            const headerSpan = th.querySelector('span');
            if (headerSpan) {
                headerSpan.style.whiteSpace = 'nowrap';
                headerSpan.style.overflow = 'hidden';
                headerSpan.style.textOverflow = 'ellipsis';
            }

            // Update all td cells
            tdCells.forEach((cell) => {
                cell.style.width = `${newWidth}px`;
                cell.style.minWidth = `${newWidth}px`;
            });

            // Update column width in state
            const updatedTableHeaderData = [...tableHeaderData];
            updatedTableHeaderData[index] = {
                ...updatedTableHeaderData[index],
                columnWidth: newWidth,
            };
            setTableHeaderData(updatedTableHeaderData);
        };

        const onMouseUp = () => {
            // Remove visual feedback
            resizeHandle.classList.remove("resizing");
            document.body.style.cursor = "";

            // Create a copy of tableHeaderData and update columnWidth
            const updatedTableHeaderData = [...tableHeaderData];
            updatedTableHeaderData[index] = {
                ...updatedTableHeaderData[index],
                columnWidth: th.offsetWidth,
            };

            // Update the state with the new column width
            setTableHeaderData(updatedTableHeaderData);

            // Store the updated tableHeader in localStorage
            localStorage.setItem("tableHeader", JSON.stringify(updatedTableHeaderData));

            // Remove event listeners
            document.removeEventListener("mousemove", onMouseMove);
            document.removeEventListener("mouseup", onMouseUp);
            document.removeEventListener("selectstart", preventDefault);
        };

        const preventDefault = (e) => e.preventDefault();

        // Prevent text selection while resizing
        document.addEventListener("selectstart", preventDefault);
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };

    const [tags, setTags] = useState(item.tags);
    const [isSectionVisible, setIsSectionVisible] = useState(true);
    const { makeUniqueId } = useCustomComposable();
    const allTags = useSelector((state) => state.tags.tags);
    const selectedTags = useSelector((state) => state.tagFilter.selectedTags); // Get selected tags from the store
    const inputRef = useRef(null);
    const [sectionTagsData, setSectionTagsData] = useState([]);
    const [tasksToShow, setTasksToShow] = useState(10); 
    const [isEditing, setIsEditing] = useState(item.isEditing || false);
    const [sectionName, setSectionName] = useState(item.value);
    const dispatch = useDispatch();

    const sections = useSelector(state => state.sections.sections);

    const handleSectionNameChange = (e) => {
        setSectionName(e.target.value);
    };

    useEffect(() => {
        setSectionName(item.value);
    }, [item.value]);

    const handleSectionNameBlur = () => {
        if (sectionName.trim()) {
            dispatch(updateSection({
                id: item.id,
                field: 'value',
                value: sectionName.trim()
            }));
            setIsEditing(false);
        }
    };

    // For section name editing
    const handleSectionNameKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            const trimmedName = sectionName.trim();
            if (trimmedName.length >= 3) {
                handleSectionNameBlur();
                inputRef.current?.blur();
            } else {
                toast.error('Section name must be at least 3 characters long');
                // Keep focus on input
                inputRef.current?.focus();
            }
        }
    };

    // Get tasks for a specific section
    const getTasksForSection = () => {
        const tmp = tasks.filter((task) => task.sectionId === id && !task.taskId);
        setRows(tmp);
    };

    useEffect(() => {
        getTasksForSection();
    }, [tasks]);

    const handleTagRemove = (index) => {
        setTags((prevTags) => prevTags.filter((_, i) => i !== index));
    };

    const [, ref] = useDrag({
        type: ItemType,
        item: { id, index },
    });

    const [, drop] = useDrop({
        accept: ItemType,
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveSection(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    const toggleVisibility = () => {
        setIsSectionVisible(!isSectionVisible);
    };

    // HANDLE TAGS
    useEffect(() => {
        const tmpSectionTags = sectionTags?.map((x) => allTags.find(y => y.id === x))?.filter((x) => x);
        setSectionTagsData(tmpSectionTags || []);
    }, [sectionTags, allTags]);

    function handleAddTag(tagName) {
        if (!tagName) return;

        const allTaskTags = [...sectionTagsData];
        const tagValue = allTaskTags.find(x => x.value === tagName);

        if (tagValue) {
            toast.error("Please enter a unique tag.");
            return;
        }

        const existingTag = allTags.find(tag => tag.value === tagName);

        // Add the tag
        if (existingTag) {
            dispatch(addTagToSection({ sectionId: item.id, tag: existingTag.id }));
            toast.success(`Tag "${existingTag.value}" added successfully!`);
        } else {
            const uniqueId = makeUniqueId(); // Implement this function to generate a unique ID
            addTagHelper({ id: uniqueId, value: tagName }, dispatch); // Ensure this helper function works correctly
            dispatch(addTagToSection({ sectionId: item.id, tag: uniqueId }));
            toast.success(`New tag "${tagName}" created and added!`);
        }
    }

    const handleRemoveTag = (tag) => {
        const removeTagId = sectionTags.find((x) => x === tag.id);
        dispatch(updateSectionTags(item.id, removeTagId, 'remove'));
        toast.success(`Tag "${tag.id}" removed successfully!`);
    };

    // Filter rows based on selected tags
    const params = searchParams.get("sectionId");
    const [showMore, setShowMore] = useState(false);

    const initialTasks = id === "all_tasks"
    ? tasks.filter(task => !task.taskId) // Only include main tasks
    : selectedTags.length > 0
        ? rows.filter(row => !row.taskId && row.tags.some(tag => selectedTags.includes(tag)))
        : [
            // Tasks from another section with the same section tag (only main tasks)
            ...tasks.filter(task =>
                !task.taskId && // Only include main tasks
                task.tags.some(tag => sectionTags.includes(tag)) &&
                !rows.includes(task)
            ),
            // Existing tasks in the section (only main tasks)
            ...rows.filter(row => !row.taskId)
        ];


    useEffect(() => {
        // Only count main tasks for the showMore state
        const mainTasksCount = initialTasks.filter(task => !task.taskId).length;
        if (tasksToShow >= mainTasksCount) {
            setShowMore(false);
        } else {
            setShowMore(true);
        }
    }, [tasksToShow, initialTasks]);
    let filteredRows;
    if (params === null) {
        filteredRows = initialTasks.slice(0, tasksToShow);
    } else {
        filteredRows = initialTasks;
    }

    useEffect(() => {
        setIsSectionVisible(selectedTags.length === 0 || filteredRows.length > 0);
    }, [selectedTags, filteredRows]);

    const handleShowMore = () => {
        setTasksToShow((prev) => prev + 10);
    };

    // Preprocess tasks and subtasks
    const processedTasks = filteredRows.map(row => {
        // Only include rows that do not have a taskId
        if (!row.taskId) {
            const subtasks = tasks.filter(subtask => subtask.taskId === row.id);
            return { row, subtasks };
        }
        return null; // Exclude tasks with taskId
    }).filter(task => task !== null); // Remove null entri

    return (
        <div className="main-wrapper">
            <div className="sticky-container">
                {params !== "all_tasks" && (
                    <>
                        <div ref={(node) => ref(drop(node))} className={`section-header ${isSectionVisible ? "" : "hidden"}`}>
                            <div className="section-left">
                                <img className="section-drag-icon" src={DragIcon} alt="DragIcon" />
                                <img
                                    className={`section-dropdown-icon ${!isSectionVisible ? "rotate" : ""}`}
                                    src={Dropdown}
                                    alt="Dropdown"
                                    onClick={toggleVisibility}
                                    style={{ cursor: "pointer" }}
                                />
                                <InputBox
                                    ref={inputRef}
                                    title={sectionName}
                                    value={sectionName}
                                    onChange={handleSectionNameChange}
                                    onBlur={handleSectionNameBlur}
                                    onKeyDown={handleSectionNameKeyPress}
                                    autoFocus={isEditing}
                                    id={`section-input-${item.id}`}
                                />
                                <RangeBar initialValue={0} />
                            </div>
                        </div>
    
                        <div className={`section-tags ${!isSectionVisible ? "hidden" : ""}`}>
                            All <Tags tags={sectionTagsData} tagOptions={allTags} handleAddTag={handleAddTag} handleRemoveTag={handleRemoveTag} />
                        </div>
                    </>
                )}
            </div>
            <div className={`style-scroll section-table ${!isSectionVisible ? "hidden" : ""}`}>
                <table className="tasks-table">
                    <thead>
                        <tr className="tasks-table-header">
                            {tableHeaderData.map((header, index) => (
                                header.label !== "Section" || params !== "all_tasks" ? (
                                    <th
                                        key={index}
                                        style={{
                                            width: `${header.columnWidth}px`,
                                            minWidth: `${header.columnWidth}px`,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            position: 'relative'
                                        }}
                                    >
                                        <div style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            position: 'absolute',
                                            left: '15px',
                                            right: '20px',
                                            bottom: '6px',
                                        }}>
                                            {header.label}
                                        </div>
                                        <span
                                            className="resize-handle"
                                            onMouseDown={(e) => handleResize(e, index)}
                                        ></span>
                                    </th>
                                ) : null
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {params !== "all_tasks" && (
                            <AddRow
                                taskValue={taskValue}
                                handleTaskChange={handleTaskChange}
                                sectionTagsData={sectionTagsData}
                                sectionId={id}  // Pass section ID to AddRow
                                onCreateTask={onCreateTask}
                            />
                        )}
                        {processedTasks.map(({ row, subtasks }) => (
                            <React.Fragment key={row.id}>
                                <TaskRow
                                    row={row}
                                    sectionTags={sectionTags}
                                    handleTaskChange={(e) => handleTaskRowChange(e, row.id)}
                                    handleStatusChange={(status) => handleTaskRowChange(status, row.id)}
                                    handleDueDateChange={(date) => handleTaskRowChange(date, row.id)}
                                    handleOrganisationChange={(organisation) => handleTaskRowChange(organisation, row.id)}
                                    handleAssigneeChange={(assignee) => handleTaskRowChange(assignee, row.id)}
                                    handleCommentsChange={(comments) => handleTaskRowChange(comments, row.id)}
                                    handleTagsChange={(tagIndex) => handleTaskRowChange(tagIndex, row.id)}
                                    index={row.id}
                                    tableHeaderData={tableHeaderData}
                                    setTableHeaderData={setTableHeaderData}
                                    subtasks={subtasks}
                                />
                            </React.Fragment>
                        ))}
                        {showMore && showMoreButton && (
                            <Button onClick={handleShowMore} text='Show More' />
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default DraggableSection;
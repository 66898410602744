import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { deleteTask } from '../../../store/Task/index';
import './Sidebar.css';
import Arrow from '../../../assets/images/svg/Left_arrow.svg';
import option from "../../../assets/images/svg/option.svg";
import Delete from "../../../assets/images/svg/Delete.svg";
import { useSidebar } from "../../context/SidebarContext/SidebarContext";
import Dropdown from '../../Atom/CustomDropDown/CustomDropDown';
import Button from '../Button/Button';
import { toast } from "react-toastify";
const Sidebar = () => {
    const dispatch = useDispatch();
    const { 
        isOpen, 
        title, 
        component, 
        closeSidebar, 
        backgroundColor, 
        showOptions,
        taskId 
    } = useSidebar();
    
    const [isChildClosedFromParent, setIsChildClosedFromParent] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    const handleCloseChildFromParent = () => {
        setIsChildClosedFromParent(true);
    };

    useEffect(() => {
        if (isChildClosedFromParent) {
            setIsChildClosedFromParent(false);
        }
    }, [isChildClosedFromParent]);

    const handleDeleteClick = () => {
        setShowDeleteConfirm(true);
        handleCloseChildFromParent();
    };
    
    const handleConfirmDelete = () => {
        if (taskId) {
            dispatch(deleteTask(taskId));
            closeSidebar();
            toast.success("Task deleted successfully");
        }
        setShowDeleteConfirm(false);
    };

    return (
        <div className={`sidebar-right style-scroll ${isOpen ? 'open' : ''}`}
         style={{ backgroundColor }}>
            <div style={{ 
                padding: "10px", 
                zIndex: "1111", 
                display: "flex", 
                gap: "10px", 
                borderBottom: "1px solid rgba(237, 238, 244, 1)" 
            }}>
                <div className='sidebar-header'>
                    <div className='header-right'>
                        <div className='sidebar-arrow'>
                            <img src={Arrow} alt="Arrow" onClick={closeSidebar} />
                        </div>
                        <div>
                            {title}
                        </div>
                    </div>
                    </div>
                {showOptions && (
                    <div className="dropdown-container">
                        <Dropdown
                            close={isChildClosedFromParent}
                            trigger={
                                <div className="dropdown-option">
                                    <img
                                        src={option}
                                        alt="options"
                                        className="option-icon"
                                    />
                                </div>
                            }
                        >
                            <div className="dropdown-suggestions">
                                <div
                                    className="dropdown-item"
                                    onClick={handleDeleteClick}
                                >
                                    <div><img src={Delete} alt="Delete" /></div>
                                    <div>Delete Task</div>
                                </div>
                            </div>
                        </Dropdown>
                    </div>
                )}
            </div>
            {showDeleteConfirm && (
                <div className="delete-confirm-banner">
                    <div className="delete-icon-container">
                        <div>
                            <img src={Delete} alt="Delete" className="delete-icon" />
                        </div>
                        <div className="delete-text">Are you sure?</div>
                    </div>
                    <div className="delete-actions">
                        <div className='cancel-button'>
                            <Button
                                text={"Cancel"}
                                onClick={() => setShowDeleteConfirm(false)}
                            />
                        </div>
                        <div className='delete-button'>
                            <Button
                                onClick={handleConfirmDelete}
                                text={"Delete"}
                            />
                        </div>
                    </div>
                </div>
            )}
            <div className="sidebar-content">
                {component}
            </div>
        </div>
    );
};

export default Sidebar;
import React, { useState } from "react";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import "./upcomingTask.css";
import CircularProgressBar from "../../Atom/CircularProgressbar/circularProgressBar";

const UpcomingTasks = ({ tasks }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  // Function to handle date selection
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const dueDates = new Set(tasks.map(task => new Date(task.dueDate).toDateString()));

  const modifiers = {
    today: (date) => date.toDateString() === new Date().toDateString(), 
    hasDueDate: (date) => dueDates.has(date.toDateString()),
    selected: (date) => selectedDate && date.toDateString() === selectedDate.toDateString(),
  };
  const modifiersClassNames = {
    today: "rdp-today",
    hasDueDate: "task-date-dot",
    selected: "rdp-selected",
  };
  
  
  

  const filteredTasks = selectedDate
    ? tasks
        .filter(task => {
          const taskDate = new Date(task.dueDate);
          return taskDate >= selectedDate;
        })
        .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate))
        .slice(0, 5)
    : [];

  return (
    <div className="upcoming-tasks">
      <div className="tasks-containers">
        <h3 className="title">Upcoming</h3>
        <div className="upcoming-task-list style-scroll">
          {selectedDate === null ? (
            <p className="no-task">Please select a date</p>
          ) : filteredTasks.length > 0 ? (
            filteredTasks.map((task, index) => {
              const taskDate = new Date(task.dueDate);
              const isValidDate = !isNaN(taskDate.getTime());
              return (
                <div key={index} className="task-item">
                  <span className="task-name">{task.value}</span>
                  <span className="upcoming-right">
                    <span className="task-status">
                      <CircularProgressBar percentage={task.status} />
                    </span>
                    <span className="task-date">
                      {isValidDate ? format(taskDate, "dd/MM") : "Invalid date"}
                    </span>
                  </span>
                </div>
              );
            })
          ) : (
            <p className="no-task">No upcoming tasks</p>
          )}
        </div>
      </div>
      <div className="calendar-container">
        <DayPicker
          captionLayout="dropdown"
          startMonth={new Date(2015, 6)}
          endMonth={new Date(2025, 9)}
          mode="single"
          selected={selectedDate}
          onDayClick={handleDateChange}
          showOutsideDays
          modifiers={modifiers}
          modifiersClassNames={modifiersClassNames}
        />
      </div>
    </div>
  );
};

export default UpcomingTasks;
import React from "react";
import "./recentTask.css";
import RecentTaskSvg from '../../../assets/images/svg/RecentTask.svg';
import CircularProgressBar from "../../Atom/CircularProgressbar/circularProgressBar";
import { formatDisplayDate } from '../../utils/composables/index';

const RecentTask = ({ tasks }) => {
  return (
    <div className="recent-task">
      <h3 className="recent-task-title">Recent Task</h3>
      <div className="task-list style-scroll">
        {tasks?.length > 0 ? (
          tasks.map((task, index) => (
            <div key={index} className="task-item">
              <span className="task-name">{task.value}</span>
              <span className="right">
                <span className="task-status">
                  <span className="checkmark">
                    <CircularProgressBar percentage={task.status}/>
                  </span>
                </span>
              <span className="task-date">{task.dueDate ? formatDisplayDate(task.dueDate) : "No date"}</span>
              </span>
            </div>
          ))
        ) : (
          <div className="no-task"><img src={RecentTaskSvg} alt="Recent Task" /></div>
        )}
      </div>
    </div>
  );
};

export default RecentTask;
import React, { useEffect, useRef, useState } from "react";
import InputBox from "../../Atom/Input/InputField";
import CircularProgressBar from "../../Atom/CircularProgressbar/circularProgressBar";
import MyDatePicker from "../../Atom/Date/Date";
import ImageGallery from "../../Atom/Image/Image";
import Tags from "../../Atom/Tags/tagsV2";
import OrganisationIcon from "../../../assets/images/svg/Organisation.svg";
import AssigneeIcon from "../../../assets/images/svg/Assignee.svg";
import CommentsIcon from "../../../assets/images/svg/Comments.svg";
import { useDispatch, useSelector } from "react-redux";
import { updateTask, updateTaskTags, deleteTask, addTask } from "../../../store/Task/index"; // Import the updateTask action
import { toast } from 'react-toastify';
import useCustomComposable from "../../utils/composables";
import { addTagHelper, updateTagCountHelper } from '../../Atom/Tags/helper';
import { useSidebar } from "../../context/SidebarContext/SidebarContext";
import TaskDetails from "../../organisms/taskDetails";
import DragIcon from "../../../assets/images/svg/Drag_icon.svg";
import { useSearchParams } from "react-router-dom";
import Comments from "../CommentSection/Comment";
import { formatDisplayDate } from '../../utils/composables/index';
import Dropdown from "../../../assets/images/svg/DropDown_arrow.svg";
import Add_icon from "../../../assets/images/svg/Add_icon.svg";
import Subtask_branch from "../../../assets/images/svg/Subtask_branch.svg";
import Button from "../../Atom/Button/Button";
import AddRow from './addRow'; // Import the AddRow component

const TaskRow = ({
    row,
    handleStatusChange,
    handleOrganisationChange,
    handleAssigneeChange,
    handleCommentsChange,
    handleTagsChange,
    handleTaskChange,
    index,
    sectionTags,
    tableHeaderData,
    setTableHeaderData,
    subtasks = [] // Pass subtasks as a prop
}) => {
    const { openSidebar } = useSidebar();
    const dispatch = useDispatch();
    const { makeUniqueId } = useCustomComposable();
    const [searchParams] = useSearchParams();
    const allTags = useSelector((state) => state.tags.tags);
    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(row.value || "");
    const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to manage sidebar
    const inputRef = useRef(null);
    const [taskTags, setTaskTags] = useState([]);
    const [taskSectionTags, setSectionTags] = useState([]);
    const [isHovered, setIsHovered] = useState(false); // State to track hover
    const [showAddRow, setShowAddRow] = useState(false); // State to manage visibility of AddRow
    const [taskValue, setTaskValue] = useState(''); // State for the task value
    const [showSubtasks, setShowSubtasks] = useState(false); // State to manage visibility of subtasks

    // Add this selector to get sections data
    const sections = useSelector((state) => state.sections.sections);
    
    // Get the section data for this task
    const taskSection = sections.find(section => section.id === row.sectionId);
    const [subtaskTagsMap, setSubtaskTagsMap] = useState({});
    useEffect(() => {
        const tmpTaskTags = row.tags?.filter((x) => !sectionTags.includes(x))?.map((x) => allTags?.find(y => y.id === x));
        const tmpSectionTags = sectionTags?.map((x) => allTags?.find(y => y.id === x));
        setTaskTags(tmpTaskTags?.filter((x) => x));
        setSectionTags(tmpSectionTags.filter((x) => x));

        const newSubtaskTagsMap = {};
        subtasks?.forEach(subtask => {
            const processedTags = subtask.tags
                ?.filter((x) => !sectionTags.includes(x))
                ?.map((x) => allTags?.find(y => y.id === x))
                ?.filter((x) => x);
            
            if (processedTags?.length > 0) {
                newSubtaskTagsMap[subtask.id] = processedTags;
            }
        });
        
        setSubtaskTagsMap(newSubtaskTagsMap);
    }, [row.tags, sectionTags,subtasks]);

    const handleDoubleClick = () => {
        setIsEditing(true);
        setInputValue(row.value); // Reset the input value when entering edit mode
        setTimeout(() => {
            inputRef.current?.focus();
        });
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleDueDateChange = (date) => {
        dispatch(updateTask({ 
            id: row.id, 
            field: 'dueDate', 
            value: date
        }));
    };

    const handleBlurOrEnter = (e) => {
        if (e.type === "blur" || (e.type === "keydown" && e.key === "Enter")) {
            if (inputValue.length >= 3) {
                dispatch(updateTask({ id: row.id, field: 'value', value: inputValue }));
                setIsEditing(false);
                setInputValue(row.value);
            } else {
                alert("Input must be at least 3 characters long.");
            }
        }
    };

    const handleRowClick = () => {
        openSidebar(
            "Task Details",
            <div className="sidebar-container">
                <div className="sideBar-taskDetails">
                    <TaskDetails 
                        taskName={row.value} 
                        taskTags={taskTags} 
                        sectionTags={taskSectionTags} 
                        section={taskSection}
                        dueDate={row.dueDate}
                        formattedDueDate={formatDisplayDate(row.dueDate)}
                    />
                </div>
                <div className="flex-filler" />
                <div className="comemnt">
                    <Comments taskId={row.id}/>
                </div>
            </div>,
            '#FFFFFF', // background color
            true, // show options
            row.id  // Pass just the row.id, not a function
        );
    };



    const handleAddTag = (tagName, isSubtask = false, subtaskId = null) => {
    
        if (!tagName) return;
    
        // Get the current task (either parent or subtask)
        const currentTask = isSubtask 
            ? subtasks.find(st => st.id === subtaskId)
            : row;

        if (!currentTask) {
            console.error('Task not found');
            return;
        }
    
        // Get current tags
        const currentTags = currentTask.tags || [];
    
        // Check if tag already exists
        const existingTag = allTags?.find(tag => tag.value === tagName);
        const isDuplicate = currentTags.some(tagId => {
            const tag = allTags.find(t => t.id === tagId);
            return tag?.value === tagName;
        });
    
        if (isDuplicate) {
            toast.error("This tag already exists in the task.");
            return;
        }
    
        // Add the tag
        if (existingTag) {
            // Use existing tag
            const updatedTags = [...new Set([...currentTags, existingTag.id])];
            dispatch(updateTaskTags({ 
                taskId: currentTask.id, 
                tags: updatedTags 
            }));
    
            if(existingTag?.id) {
                updateTagCountHelper({tagId: existingTag.id, type: "add"}, dispatch);
            }
            toast.success(`Tag "${existingTag.value}" added successfully!`);
        } else {
            // Create new tag
            const uniqueId = makeUniqueId();
            addTagHelper({ id: uniqueId, value: tagName }, dispatch);
            
            const updatedTags = [...new Set([...currentTags, uniqueId])];
            
            dispatch(updateTaskTags({ 
                taskId: currentTask.id, 
                tags: updatedTags 
            }));
            
            toast.success(`New tag "${tagName}" created and added!`);
        }
    };



    const handleRemoveTag = (tag, isSubtask = false, subtaskId = null) => {
        if (isSubtask) {
            const subtask = subtasks.find(st => st.id === subtaskId);
            if (!subtask) return;
            
            const updatedTags = subtask.tags.filter((x) => x !== tag.id);
            dispatch(updateTask({ 
                id: subtaskId, 
                field: 'tags', 
                value: updatedTags 
            }));
        } else {
            const updatedTags = row.tags.filter((x) => x !== tag.id);
            dispatch(updateTask({ 
                id: row.id, 
                field: 'tags', 
                value: updatedTags 
            }));
        }
        
        updateTagCountHelper({tagId: tag.id}, dispatch);
        toast.success(`Tag "${tag.value}" removed successfully!`);
    };
    const params = searchParams.get("sectionId");
    const handleExpandColumn = (index) => {
        if (index < 0 || index >= tableHeaderData.length) {
            console.error('Column not found at index:', index);
            return;
        }
    
        const newTableHeaderData = [...tableHeaderData];
    
        // Check if the current column exists
        const currentColumn = newTableHeaderData[index + 1];
        if (!currentColumn) {
            console.error(`Column at index ${index} is undefined.`);
            return;
        }
    
        const currentColumnWidth = currentColumn.columnWidth; // Use index directly
    
        if (typeof currentColumnWidth === 'number') {
            // Update the column width to the new calculated width
            newTableHeaderData[index + 1] = {
                ...currentColumn,
                columnWidth: currentColumnWidth + 100, // Increase width by 100px
            };
        } else {
            console.error(`Column at index ${index} has an invalid columnWidth value.`);
            return;
        }
    
        // Update the state with the new table header data
        setTableHeaderData(newTableHeaderData);
    };

    const tagsColumnWidth = tableHeaderData.find(header => header.label === "Tags")?.columnWidth || 200;

    const handleCreateTask = (taskData) => {
        const nonSpaceCharCount = taskData.taskName.replace(/\s/g, "").length;

        // Validation for at least 3 non-space characters
        if (nonSpaceCharCount < 3) {
            toast.error("Task name must contain at least 3 non-space characters.");
            return;
        }

        // Get section tags
        const sectionTags = sections
            .filter((e) => e.id === taskData.sectionId)
            .flatMap((e) => e?.tags ?? []);

        const newTask = {
            id: makeUniqueId(),
            sectionId: taskData.sectionId,
            value: taskData.taskName.trim(),
            status: 0,
            dueDate: "",
            organisation: "",
            assignee: "",
            comments: false,
            tags: [...(taskData.tags || []), ...sectionTags],
            createdAt: new Date().toISOString(),
            taskId: row.id
        };
        // Dispatch to Redux
        dispatch(addTask(newTask));
        setTaskValue(''); // Add this line to clear the inpu
        // Clear task input
        // const newSections = sections.map((section) =>
        //     section.id === taskData.sectionId ? { ...section, taskValue: "" } : section
        // );
        // setSections(newSections);

        toast.success('Sub Task created successfully!');
    };

    return (
        <>
            <tr 
               
                onMouseEnter={() => setIsHovered(true)} 
                onMouseLeave={() => setIsHovered(false)} // Track hover state
                // style={{ opacity: subtasks.length === 0 ? '0.1  ' : '1' }} 
            >
                <td style={{ position: 'relative' }}>
                    <div className="taskNameCell">
                        <img src={DragIcon} />
                        <img
                            src={Dropdown}
                            alt="Dropdown"
                            style={{ cursor: "pointer", marginLeft: '5px', transform: showSubtasks ? 'rotate(0deg)' : 'rotate(-90deg)', transition: 'transform 0.3s', opacity: subtasks.length === 0 ? '0.1  ' : '1' }} // Rotate icon
                            onClick={() => setShowSubtasks(!showSubtasks)} // Toggle subtasks visibility
                        />
                       
                        <span className="task-name">
                        {isEditing ? (
                            <InputBox
                            
                                ref={inputRef}
                                value={inputValue}
                                onChange={handleInputChange}
                                onBlur={handleBlurOrEnter}
                                onKeyDown={handleBlurOrEnter} // Use only onKeyDown for handling Enter
                                autoFocus
                            />
                        ) : (
                            <span  onClick={handleRowClick} onDoubleClick={handleDoubleClick}>
                                {row.value || "Double-click to edit"}
                            </span>
                        )}
                        {subtasks.length > 0 && 
                        <span className="subtask_branch">
                            <img src={Subtask_branch} alt="subtask branch"/>
                            {subtasks.length}
                        </span>
                        }
                        {isHovered && (
                            <span className="add-button">
                                <Button
                                    type="trasperant"   
                                    imageSrc={Add_icon}
                                    onClick={() => setShowAddRow(!showAddRow)}
                                />
                            </span>
                        )}
                        </span>
                    </div>
                </td>
                <td>
                    <CircularProgressBar
                        percentage={row.status}
                        onChange={(e) => handleStatusChange(e, row.id)}
                    />
                </td>
                <td>
                    <MyDatePicker
                        selectedDate={row.dueDate}
                        onChange={handleDueDateChange}
                        disabled={false}
                    />
                </td>
                <td>
                    <div className="align-center">
                        {row.organisation?.length ? (
                            <ImageGallery images={[row.organisation]} />
                        ) : (
                            <img src={OrganisationIcon} alt="Organisation" />
                        )}
                    </div>
                </td>
                <td>
                    <div className="align-center">
                        {row.assignee?.length ? (
                            <ImageGallery images={[...row.assignee]} shape="circle" />
                        ) : (
                            <img src={AssigneeIcon} alt="Assignee" />
                        )}
                    </div>
                </td>
                <td>
                    <div className="align-center">
                        <img src={CommentsIcon} alt="Comments" />
                    </div>
                </td>
                <td>
                    <Tags
                        tags={taskTags || []}
                        tagOptions={allTags || []}
                        handleAddTag={(tagName) => handleAddTag(tagName, false)}
                        handleRemoveTag={handleRemoveTag}
                        onRemoveTag={handleRemoveTag}
                        columnWidth={tagsColumnWidth}
                        minVisibleTags={2}
                        onExpandColumn={() => handleExpandColumn(5)}
                    />
                </td>
                {params !== "all_tasks" && (
                    <td>
                        <Tags
                            tags={taskSectionTags || []}
                            disabled={true}
                            handleRemoveTag={handleRemoveTag}
                            taskId={row.id}
                            onRemoveTag={handleRemoveTag}
                            onExpandColumn={() => handleExpandColumn(5)} 
                        />
                    </td>
                )}
            </tr>
            {showAddRow && (
          
                        <AddRow
                            taskValue={taskValue}
                            handleTaskChange={(e) => setTaskValue(e.target.value)}
                            sectionTagsData={taskSectionTags}
                            sectionId={row.sectionId}
                            onCreateTask={handleCreateTask}
                        />
            )}
            {/* Render subtasks if visible */}
            {showSubtasks && (
                <>
                    {subtasks.map((subtask) => (
                          <tr key={subtask.id} className="subtask-row">
                          <td>
                              <div className="subtask-container">
                                  <div className="subtask-text">{subtask.value}</div>
                              </div>
                          </td>
                            <td>
                                <CircularProgressBar
                                    percentage={subtask.status}
                                />
                            </td>
                            <td>
                                <MyDatePicker
                                    selectedDate={subtask.dueDate}
                                    onChange={(date) => dispatch(updateTask({ id: subtask.id, field: 'dueDate', value: date }))}
                                    disabled={false}
                                />
                            </td>
                            <td>
                                <div className="align-center">
                                    {subtask.organisation.length ? (
                                        <ImageGallery images={[subtask.organisation]} />
                                    ) : (
                                        <img src={OrganisationIcon} alt="Organisation" />
                                    )}
                                </div>
                            </td>
                            <td>
                                <div className="align-center">
                                    {subtask.assignee.length ? (
                                        <ImageGallery images={[...subtask.assignee]} shape="circle" />
                                    ) : (
                                        <img src={AssigneeIcon} alt="Assignee" />
                                    )}
                                </div>
                            </td>
                            <td>
                                <div className="align-center">
                                    <img src={CommentsIcon} alt="Comments" />
                                </div>
                            </td>
                            <td>
                                <Tags
                                     tags={subtaskTagsMap[subtask.id] || []}
                                    tagOptions={allTags || []}
                                    handleAddTag={(tagName) => handleAddTag(tagName, true, subtask.id)}
                                    handleRemoveTag={handleRemoveTag}
                                    columnWidth={tagsColumnWidth}
                                    minVisibleTags={2}
                                    onExpandColumn={() => handleExpandColumn(5)} 
                                />
                            </td>
                            {params !== "all_tasks" && (
                    <td>
                        <Tags
                            tags={taskSectionTags || []}
                            disabled={true}
                            handleRemoveTag={handleRemoveTag}
                            taskId={row.id}
                            onRemoveTag={handleRemoveTag}
                            onExpandColumn={() => handleExpandColumn(5)} 
                        />
                    </td>
                )}
                        </tr>
                    ))}
                </>
            )}
           
        </>
    );
};

export default TaskRow;
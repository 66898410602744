import { updateSectionTags } from '../../../store/Section';
import { addTag, updateCount } from '../../../store/Tags/index';
import { updateTaskTags } from '../../../store/Task/index'; // Update the import for updateTaskTags

// Helper function to add a new tag
export const addTagHelper = (newTag, dispatch) => {
    // Dispatch the action to add the new tag
    dispatch(addTag({...newTag, count: 1 ,createdAt: new Date().toISOString()}));
    
    // dispatch(addTag({...newTag,}));
};

export const updateTagCountHelper = (tagId, dispatch) => {
    dispatch(updateCount(tagId));
} 

// Helper function to remove a tag
export const removeTagHelper = (tags, index, taskId, dispatch) => {
    const tagToRemove = tags[index];
    // Filter out the tag at the specified index
    const updatedTags = tags?.filter((_, tagIndex) => tagIndex !== index);
    // Dispatch the action to update task tags
    dispatch(updateTaskTags({ taskId, tags: updatedTags })); 
    dispatch(updateSectionTags(taskId, tagToRemove, 'remove'));
    return updatedTags;
};

// Helper function to filter tags based on input value
export const filterTags = (inputValue, tags) => {
    // Ensure tags is always an array
    const tagsArray = Array.isArray(tags) ? tags : [];

    return tagsArray.filter(tag => 
        tag.value && tag.value.toLowerCase().includes(inputValue.toLowerCase())
    );
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Badge from '../../Atom/Badge/badge';
import { addFilterTag, removeFilterTag, resetFilter } from '../../../store/tagFilter/index'; // Adjust the path as necessary
import './tagFilter.css';
import Button from '../../Atom/Button/Button';

const TagFilter = ({ tags }) => { // Accept tags as a prop
    const dispatch = useDispatch();
    const selectedTags = useSelector((state) => state.tagFilter.selectedTags); // Get selected tags from the store
    const tasks = useSelector((state) => state.tasks.tasks);
    const [recentTags, setRecentTags] = useState([]);
    const [favouriteTags, setFavouriteTags] = useState([]);
    const [mostAssignedTags, setMostAssignedTags] = useState([]);

    const groupAlphabetically = (tagsArray) => {
        return tagsArray?.reduce((groups, tag) => {
            const letter = tag.value[0].toUpperCase();
            if (!groups[letter]) {
                groups[letter] = [];
            }
            groups[letter].push(tag);
            return groups;
        }, {});
    };

    useEffect(() => {
        const updateRecentTags = () => {
            const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
            const recent = tags?.filter(tag => {
                if (!tag.createdAt) return false;
                const tagDate = new Date(tag.createdAt);
                return tagDate > sevenDaysAgo;
            });

            const sortedRecentTags = recent
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .slice(0, 9);

            setRecentTags(sortedRecentTags);
        };

        updateRecentTags();
    }, [tags]);

    useEffect(() => {
        const calculateFavouriteTags = () => {
            const tagUsageCount = {};

            tasks.forEach(task => {
                task.tags.forEach(tagId => {
                    if (tagUsageCount[tagId]) {
                        tagUsageCount[tagId]++;
                    } else {
                        tagUsageCount[tagId] = 1;
                    }
                });
            });

            const favouriteTagsArray = tags.map(tag => ({
                ...tag,
                usageCount: tagUsageCount[tag.id] || 0
            }));

            const sortedFavouriteTags = favouriteTagsArray
                .sort((a, b) => b.usageCount - a.usageCount)
                .slice(0, 16);

            setFavouriteTags(sortedFavouriteTags);
        };

        calculateFavouriteTags();
    }, [tasks, tags])

    useEffect(() => {
        const updateMostAssignedTags = () => {
            const sortedTags = [...tags]
                .sort((a, b) => b.count - a.count)
                .slice(0, 5);
            setMostAssignedTags(sortedTags);
        };

        updateMostAssignedTags();
    }, []);

    const handleTagClick = (tag) => {
        const isSelected = selectedTags.includes(tag.id);

        if (isSelected) {
            dispatch(removeFilterTag(tag.id));
        } else {
            dispatch(addFilterTag(tag.id));
        }
    };

    const handleResetFilters = () => {
        dispatch(resetFilter()); // Dispatch the resetFilter action
    };

    return (
        <div className="tag-filter-wrapper">
            <div className='reset-button'>
                <Button
                    text='Reset Filter'
                    onClick={handleResetFilters}
                />
            </div>

            <section className="tag-section">
                <h2 className="section-heading">Favourite</h2>
                <div className="tag-content">
                    {favouriteTags.length > 0 ? (
                        <div className="badge-group">
                            {favouriteTags.map((tag) => (
                                <Badge
                                    key={tag.id}
                                    text={tag.value}
                                    backgroundColor={tag.color || '#E8E8E8'}
                                    color={tag.textColor || '#333'}
                                    onClick={() => handleTagClick(tag)}
                                    selected={selectedTags.includes(tag.id)}
                                    className="large"
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="no-favourite-tags">No favourite tags available</div>
                    )}
                </div>
            </section>

            <section className="tag-section">
                <h2 className="section-heading">Recently Added</h2>
                <div className="tag-content">
                    {recentTags.length > 0 ? (
                        <div className="badge-group">
                            {recentTags.map((tag) => (
                                <Badge
                                    key={tag.id}
                                    text={tag.value}
                                    backgroundColor={tag.color || '#E8E8E8'}
                                    color={tag.textColor || '#333'}
                                    onClick={() => handleTagClick(tag)}
                                    selected={selectedTags.includes(tag.id)}
                                    className="large"
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="no-recent-tags">No recent tags available</div>
                    )}
                </div>
            </section>

            <section className="tag-section">
                <h2 className="section-heading">Most Assigned</h2>
                <div className="tag-content">
                    {mostAssignedTags.length > 0 ? (
                        <div className="badge-group">
                            {mostAssignedTags.map((tag) => (
                                <Badge
                                    key={tag.id}
                                    text={tag.value}
                                    backgroundColor={tag.color || '#E8E8E8'}
                                    color={tag.textColor || '#333'}
                                    onClick={() => handleTagClick(tag)}
                                    selected={selectedTags.includes(tag.id)}
                                    className="large"
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="no-recent-tags">No most assigned tags available</div>
                    )}
                </div>
            </section>

            <section className="tag-section">
                <h2 className="section-heading">Others</h2>
                <div className="tag-content">
                <div className="alphabetical-tags">
                        {Object.keys(groupAlphabetically(tags) || {}).sort().map(letter => (
                            <div key={letter} className="letter-group">
                                <div className="letter-heading">{letter}</div>
                                <div className="badge-group">
                                    {groupAlphabetically(tags)[letter].map((tag) => (
                                        <Badge 
                                            key={tag.id}
                                            text={tag.value}
                                            backgroundColor={tag.color || '#E8E8E8'}
                                            color={tag.textColor || '#333'}
                                            onClick={() => handleTagClick(tag)}
                                            selected={selectedTags.includes(tag.id)}
                                            className="large"
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

        </div>
    );
};

export default TagFilter;
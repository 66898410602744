import { createSlice } from '@reduxjs/toolkit';

const loadTagsFromLocalStorage = () => {
    const storedTags = localStorage.getItem('tags');
    return storedTags ? JSON.parse(storedTags) : [];
};

const tagSlice = createSlice({
    name: 'tags',
    initialState: {
        tags: loadTagsFromLocalStorage(), // Load initial state from localStorage
    },
    reducers: {
        setTags: (state, action) => {
            state.tags = action.payload;
            localStorage.setItem('tags', JSON.stringify(state.tags)); // Save to localStorage
        },
        addTag: (state, action) => {
            state.tags = [action.payload, ...state.tags];
            localStorage.setItem('tags', JSON.stringify(state.tags)); // Save to localStorage
        },
        updateTag: (state, action) => {
            const { index, value } = action.payload;
            if (value) {
                state.tags[index] = value;
            } else {
                state.tags = state.tags.filter((_, i) => i !== index);
            }
            localStorage.setItem('tags', JSON.stringify(state.tags)); // Save to localStorage
        },
        updateCount: (state, action) => {
            const storedTags = state?.tags;
            let index = storedTags.findIndex((tag) => {
                return tag.id === action.payload.tagId
            });

            if (action.payload.type === "add") {
                if (index !== -1) {
                    storedTags[index] = { ...storedTags[index], count: storedTags[index].count + 1 }
                }
            } else {
                if (index !== -1) {
                    storedTags[index] = { ...storedTags[index], count: storedTags[index].count - 1 }
                }
            }
            localStorage.setItem('tags', JSON.stringify(storedTags)); // Save to localStorage
        }
    },
});

export const { setTags, addTag, updateTag, updateCount } = tagSlice.actions;
export default tagSlice.reducer;
